import React, { useEffect, useState } from "react";
import MenuButton from "../components/MenuButton";
import FooterSection from "../components/FooterSection";
import MissionHeroSection from "../components/MissionHeroSection";
import TextSection from "../components/TextSection";
import StatsSection from "../components/StatsSection";
import SafetyAppsSection from "../components/SafetyAppsSection";
import StratsSection from "../components/strats";
import SisterhoodSection from "../components/SisterhoodSection";
import SisterhoodExplainationSection from "../components/SisterhoodExplainationSection";
import CampaignSection from "../components/CampaignSection";
import PhasesSection from "../components/PhasesSection";
import TeamSection from "../components/TeamSection";
import { useLocation } from "react-router-dom";

// import HandsComp from "../components/HandsComp";

const MissionPage = () => {
  const location = useLocation();
  const [pageLoaded, setPageLoaded] = useState(false);
  // Scroll to the top of the page on component mount
  useEffect(() => {
    window.scrollTo(0, 0);
    window.onload = () => setPageLoaded(true);
  }, []); // The empty array ensures this effect runs only once, on mount

  useEffect(() => {
    if (location.hash === "#team-section" && pageLoaded) {
      // Ensure the page is fully loaded before scrolling
      const teamSection = document.getElementById("team-section");
      if (teamSection) {
        setTimeout(
          () => teamSection.scrollIntoView({ behavior: "smooth" }),
          100
        ); // Optionally add a slight delay
      }
    }
  }, [location.hash, pageLoaded]); // This effect depends on the hash value and the pageLoaded state.

  const [sisterhoodHeight, setSisterhoodHeight] = useState(0);
  const [sisterhoodSVG, setSisterhoodSVG] = useState(0);

  const updateDimensions = () => {
    const sisterhoodSection = document.getElementById("upspoken");
    // const textSection = document.getElementById("textSisterhood");
    const svgSection = document.getElementById("svgSection");

    if (sisterhoodSection) {
      const height = sisterhoodSection.offsetHeight;
      console.log("sisterhoodSection.offsetHeight", height);
      setSisterhoodHeight(height);
    }

    if (svgSection) {
      const height = svgSection.offsetHeight;
      console.log("svgSection.offsetHeight", height);
      setSisterhoodSVG(height);
    }
  };

  useEffect(() => {
    const handleResize = () => {
      // setIsMobile(window.innerWidth < 1000);
      updateDimensions();
    };
    handleResize(); // Calculate dimensions initially

    window.addEventListener("resize", handleResize);
    const svgImage = document.getElementById("svgSection");
    svgImage.addEventListener("load", updateDimensions); // Update dimensions when the image loads

    return () => {
      window.removeEventListener("resize", handleResize);
      svgImage.removeEventListener("load", updateDimensions);
    };
  }, []);

  return (
    <div className="flex-col bg-black">
      <MenuButton />
      <MissionHeroSection />
      <TextSection />
      <StatsSection />
      <SafetyAppsSection />
      <StratsSection />
      <div
        style={{
          minHeight: sisterhoodHeight + sisterhoodSVG,
        }}
      >
        <SisterhoodSection />
      </div>

      <SisterhoodExplainationSection />

      <CampaignSection />
      <PhasesSection />
      <TeamSection />

      <FooterSection className="flex" />
    </div>
  );
};

export default MissionPage;
