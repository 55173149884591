import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

const PartnersButtonRow = () => {
  const [isMobile, setIsMobile] = useState(false);
  const location = useLocation();

  const navigate = useNavigate();

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 1000);
    };

    // Initial check on component mount
    handleResize();

    // Event listener for window resize
    window.addEventListener("resize", handleResize);

    // Cleanup on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const buttonStyle = {
    color: "var(--BRAND-COLOURS-ONYX, #070706)",
    lineHeight: isMobile ? "16px" : "28px",
    fontFamily: "KHTeka",
    fontSize: isMobile ? "16px" : "28px",
    fontStyle: "normal",
    fontWeight: isMobile ? 300 : 400,
    letterSpacing: "-0.32px",
    // border: "5px solid black",
    // borderSize: "5px",
  };
  //   if (isMobile) {
  //     return (
  // nul;
  //     )
  //   }
  return (
    <div className={`flex flex-row w-full justify-between `}>
      <p></p>
      <button
        className={`p-3 rounded-full  ${
          location.pathname.startsWith("/Partners/Home")
            ? "bg-black text-white"
            : "border border-black border-4 bg-white"
        }
        `}
        style={buttonStyle}
        onClick={() => navigate("/Partners/Home")}
      >
        FINANCES
      </button>
      <button
        className={`p-3 rounded-full ${
          location.pathname.startsWith("/Partners/Business")
            ? "bg-black text-white"
            : "border border-black border-4 bg-white"
        }
        `}
        style={buttonStyle}
        onClick={() => navigate("/Partners/Business")}
      >
        BUSINESS
      </button>
      <button
        className={`p-3 rounded-full ${
          location.pathname.startsWith("/Partners/Research")
            ? "bg-black text-white"
            : "border border-black  border-4 bg-white"
        }
        `}
        style={buttonStyle}
        onClick={() => navigate("/Partners/Research")}
      >
        RESEARCH
      </button>
      <button
        className={` p-3 rounded-full ${
          location.pathname.startsWith("/Partners/Product")
            ? "bg-black text-white"
            : "border border-black  border-4 bg-white"
        }
        `}
        style={buttonStyle}
        onClick={() => navigate("/Partners/Product")}
      >
        PRODUCT
      </button>
      <p></p>
    </div>
  );
};

export default PartnersButtonRow;
