import React, { useEffect, useState } from "react";
import statSVG from "../assets/img/97.svg";

// const stats = [
//   // {
//   //   number: "97%",
//   //   text: "of women aged 18-24 have experienced some form of sexual harassment, ranging from catcalling to physical assaults.",
//   // },
//   // {
//   //   number: "79%",
//   //   text: "of women say that on a night out, they expected inappropriate behaviour, comments and touching towards them or their female friends.",
//   // },
//   {
//     number: "90%",
//     text: "of women reporting feeling unsafe when travelling alone at night, this is a widespread problem that significantly impacts their sense of safety.",
//   },
// ];

const TextSection = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 1000);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 1000);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  // const numberStyle = {
  //   color: "var(--BRAND-COLOURS-ONYX, #070706)",
  //   textAlign: "center",
  //   lineHeight: "78%" /* 195px */,
  //   fontFamily: "GT Flexa",
  //   fontSize: isMobile ? "120px" : "250px",
  //   fontStyle: "normal",
  //   fontWeight: "715",
  //   letterSpacing: "-2.5px",
  //   textTransform: "uppercase",
  // };

  const smallStyle = {
    color: "var(--BRAND-COLOURS-ONYX, #070706)",
    textAlign: "center",
    lineHeight: "100%" /* 24px */,
    fontFamily: "KHTeka",
    fontSize: isMobile ? "18px" : "32px",
    fontStyle: "normal",
    fontWeight: "400",
    letterSpacing: "-0.24px",
  };

  return (
    <div
      className={`bg-[#C140FF]  ${
        isMobile
          ? "flex flex-col justify-center items-center px-3 py-3"
          : "justify-center items-center py-20 px-16"
      }`}
    >
      <div
        className={` ${
          isMobile ? "w-full" : "w-1/2  mx-auto justify-center"
        }  flex-col items-start`}
        style={{ display: "flex" }}
      >
        <img
          src={statSVG}
          alt="Stat"
          className="flex mb-10 mt-5 px-10 w-full"
        />
        <p style={smallStyle} className=" w-full">
          90% of women report <b> feeling unsafe </b> when travelling{" "}
          <b>alone at night</b>
        </p>
        {/* {stats.map((stat, index) => (
          <div
            key={index}
            className="flex-col p-3"
            style={{ display: "flex", width: isMobile ? "100%" : "400px" }}
          >
            <p style={numberStyle} className="mb-0">
              {stat.number}
            </p>
            <p style={smallStyle} className="mb-10">
              {stat.text}
            </p>
          </div>
        ))} */}
      </div>
    </div>
  );
};

export default TextSection;
