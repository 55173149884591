import React, { useEffect, useRef, useState } from "react";
import MenuButton from "../components/MenuButton";
// import heroImg from "../assets/img/partners_media.png";
// import heroText from "../assets/img/partners_media.svg";

// import megaphone from "../assets/img/megaphone.svg";
// import evileye from "../assets/img/evileye.svg";
// import strength from "../assets/img/strength.svg";
// // import { useNavigate } from "react-router-dom";
import PartnersButtonRow from "../components/PartnersButtonRow";
// import RevenueStreams from "../components/RevenueStreams";
// import CostsSection from "../components/CostsSection";
// import MarketingSection from "../components/MarketingSection";
import FooterSection from "../components/FooterSection";
import ResearchToggleBig from "../components/ResearchToggleBig";

import p0 from "../assets/img/research/p0.png";
import p1 from "../assets/img/research/p1.png";
import p2 from "../assets/img/research/p2.png";
// import p3 from "../assets/img/research/p3.png";
// import p4 from "../assets/img/research/p4.png";
import p5 from "../assets/img/research/p5.png";

import pr11 from "../assets/img/research/pr11.png";
import pr12 from "../assets/img/research/pr12.png";
import pr13 from "../assets/img/research/pr13.png";
import pr14 from "../assets/img/research/pr14.png";

import pr21 from "../assets/img/research/pr21.png";
import pr22 from "../assets/img/research/pr22.png";
import pr23 from "../assets/img/research/pr23.png";
import pr24 from "../assets/img/research/pr24.png";

import pr31 from "../assets/img/research/pr31.png";
import pr32 from "../assets/img/research/pr32.png";
import pr33 from "../assets/img/research/pr33.png";
import pr34 from "../assets/img/research/pr34.png";

import t1 from "../assets/img/research/t1.png";
import t2 from "../assets/img/research/t2.gif";
import t3 from "../assets/img/research/t3.png";
import t4 from "../assets/img/research/t4.png";

import m1 from "../assets/img/research/m1.png";
import m2 from "../assets/img/research/m2.svg";
import m3 from "../assets/img/research/m3.png";

const PartnersResearchPage = () => {
  const [isMobile, setIsMobile] = useState(false);
  const [menuButtonHeight, setMenuButtonHeight] = useState(0);
  const [scrolled, setScrolled] = useState(false);
  const partnersButtonRef = useRef(null);

  useEffect(() => {
    // Calculate the height of the MenuButton component
    const menuButton = document.getElementById("menuButton");
    if (menuButton) {
      const height = menuButton.offsetHeight;
      setMenuButtonHeight(height);
    }
  }, []);

  // const navigate = useNavigate();
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 710);
    };

    // Initial check on component mount
    handleResize();

    // Event listener for window resize
    window.addEventListener("resize", handleResize);

    // Cleanup on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      const partnersButtonPosition =
        partnersButtonRef.current.getBoundingClientRect();
      if (partnersButtonPosition.top <= -50) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  // const handleRemoveUsername = () => {
  //   sessionStorage.removeItem("signedInPartners");
  //   // Optionally, you can add any additional logic here after removing the item
  // };

  // const buttonTextStyle = {
  //   color: "var(--BRAND-COLOURS-ONYX, #070706)",
  //   textAlign: "center",
  //   lineHeight: "78%", // Corresponds to 62.4px at 80px font size
  //   fontFamily: "GT Flexa",
  //   fontSize: "80px",
  //   fontStyle: "normal",
  //   fontWeight: 715,
  //   // letterSpacing: "-0.8px",
  //   textDecorationLine: "underline",
  //   textTransform: "uppercase",
  // };

  const explainStyle = {
    color: "var(--BRAND-COLOURS-OFFWHITE, #F4F1E9)",
    leadingTrim: "both",
    textEdge: "cap",
    fontFamily: "KHTeka",
    fontSize: "32px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "normal",
    letterSpacing: "0.32px",
    listStyleType: "disc",
  };

  return (
    <div className="flex flex-col bg-black">
      {!scrolled && <MenuButton className="" />}
      {scrolled && (
        <div className="sticky top-0 z-50 mx-16 pt-8">
          <PartnersButtonRow />
        </div>
      )}
      <div className={`h-full w-100 `}>
        <div
          style={{ marginTop: menuButtonHeight }}
          className={`mb-5 ${isMobile ? "px-3" : "px-16"}`}
          ref={partnersButtonRef}
        >
          <PartnersButtonRow />
        </div>
        <ResearchToggleBig
          name="Research Conducted"
          color="#C140FF"
          content={
            <ul style={explainStyle}>
              <li className="pb-3">
                Interviews and Conversations with{" "}
                <i className="text-[#C140FF]">
                  150+ users, 50+ drivers, and 17 stakeholders
                </i>
              </li>
              <li className="py-3">
                2 surveys to better understand and quantify user needs and
                habits (<i className="text-[#C140FF]">n= 365, 732</i>)
              </li>
              <li className="py-3">
                Workshops and spontaneous events to gauge interest and gather
                feedback (
                <i className="text-[#C140FF]">
                  Fresher's Fair, around Imperial College London, Kings College
                  London, London School of Economics, and University College
                  London
                </i>
                )
              </li>
              <li className="py-3">
                7 Pilots to test assumptions and UX/UI with users (
                <i className="text-[#C140FF]">
                  Metric, Union Bar, The RedBack Fulham, The Scotch of St James,
                  KOKO, O2 Forum, Embargo’s Republica, and Electric Brixton
                </i>
                )
              </li>
            </ul>
          }
          imgs={
            <div className="flex flex-row justify-between w-auto">
              <img src={p0} alt="pr11" className="w-full pr-3" />
              <img src={p1} alt="pr11" className="w-full px-3" />
              <img src={p2} alt="pr11" className="w-full px-3" />
              <img src={p5} alt="pr11" className="w-full pl-3" />
            </div>
          }
          isOpen={true}
        />
        <hr
          style={{
            border: "none",
            borderBottom: "3px solid white",
            opacity: "100%",
          }}
        />
        <p
          style={{
            color: "var(--BRAND-COLOURS-OFFWHITE, #F4F1E9)",
            leadingTrim: "both",
            textEdge: "cap",
            fontFamily: "GT Flexa",
            fontVariationSettings: "'wght' 550, 'wdth' 0",
            fontSize: "150px",
            fontStyle: "normal",
            // fontWeight: "715",
            lineHeight: "78%" /* 117px */,
            // letterSpacing: "1.5px",
            textTransform: "uppercase",
          }}
          className="px-[85px] mt-16"
        >
          TESTING & VALIDATION
        </p>
        <ResearchToggleBig
          name="❶ Women are willing to share with another woman for safety and/or cost savings"
          color="#FEDB01"
          content={
            <ul style={explainStyle}>
              <li className="pb-3">
                At The Redback Fulham we conducted our largest test to-date. We
                piloted the latest version of our{" "}
                <a
                  href="https://gethomesafe.io/MVP/LandingPage"
                  onClick={() =>
                    window.open(
                      "https://gethomesafe.io/MVP/LandingPage",
                      "_blank"
                    )
                  }
                  style={{ color: "#C140FF", textDecoration: "underline" }}
                >
                  {" "}
                  MVP WebApp
                </a>{" "}
                with core functionality: Basic information inputs and matching
                users.
              </li>
              <li className="py-3">
                We got <i className="text-[#FEDB01]">43 signups</i> total from
                both posters on the night and our social media’s story promoting
                the event.
              </li>
              <li className="py-3">
                To test the assumption, we had a team of 11 women ready to share
                with any women who signed up. Throughout the night{" "}
                <i className="text-[#FEDB01]">8 women</i> (2 singles, 3 pairs)
                ended up following through, getting matched and leaving with our
                team.
              </li>
              <li className="py-3">
                Having proved that we can order rides scalably (see technical
                assumptions below), at this pilot we manually{" "}
                <i className="text-[#FEDB01]">ordered 3 taxis</i> for the groups
                getting them to pay us for the ride and our commission through a
                square payment portal.
              </li>
              <li className="py-3">
                5 groups provided us with a revenue of 76.47£ and an{" "}
                <i className="text-[#FEDB01]">8£ profit</i>.
              </li>
            </ul>
          }
          imgs={
            <div className="flex flex-row justify-between w-auto">
              <img src={pr23} alt="pr11" className="w-full pr-3" />
              <img src={pr22} alt="pr11" className="w-full px-3" />
              <img src={pr21} alt="pr11" className="w-full px-3" />
              <img src={pr24} alt="pr11" className="w-full pl-3" />
            </div>
          }
        />
        <ResearchToggleBig
          name="❷ Users prioritise cost savings and safety over convenience or travel time"
          color="#F48F14"
          content={
            <ul style={explainStyle}>
              <li className="py-3">
                Our 2nd survey identified that{" "}
                <i className="text-[#F48F14]">
                  90% of respondents have changed their plans before to avoid
                  going home alone
                </i>
                . They estimated that this happened around{" "}
                <i className="text-[#F48F14]">50% of their nights out</i>. This
                means women already wait or leave earlier with their friends for
                their own safety.
              </li>
              <li className="py-3">
                Once our user base is large enough,{" "}
                <i className="text-[#F48F14]">
                  our users will not have to wait
                </i>{" "}
                . Additionally, if the user plans their night ahead of time (a
                habit that women already have, and that was identified through
                interviews) we match them in advance, reducing their wait.
              </li>
              <li className="py-3">
                In the case where they wait, there are several examples of women
                doing this for safety and saving costs.{" "}
                <i className="text-[#F48F14]">
                  In Naples, where a similar local service exists, women and men
                  wait up to 30 minutes at the venue to go home into a full taxi
                </i>
                . This is ride and wait is planned during the day and has been
                described as a refreshing social environment after a club.
              </li>
            </ul>
          }
          imgs={
            <div className="flex flex-row justify-between w-full">
              <img src={pr11} alt="pr11" className="w-full pr-3" />
              <img src={pr12} alt="pr11" className="w-full px-3" />
              <img src={pr13} alt="pr11" className="w-full px-3" />
              <img src={pr14} alt="pr11" className="w-full pl-3" />
            </div>
          }
        />
        <ResearchToggleBig
          name="❸ Other Learnings from Testing"
          color="#03AF70"
          content={
            <>
              <p style={explainStyle}>
                By carrying various pilots, we learnt how to better conduct
                these tests to improve our results:
              </p>
              <ul style={explainStyle}>
                <li className="py-3">
                  <i className="text-[#03AF70]">Wear high vis jackets </i>as
                  people are more inclined to talk to us
                </li>
                <li className="py-3">
                  <i className="text-[#03AF70]">
                    Our women team members should talk to the users:
                  </i>{" "}
                  We once overheard two women saying how sketchy it was for a
                  man to be initiating conversations about safety.
                </li>
                <li className="py-3">
                  The events we choose to test at should close when the tube is
                  no longer available (late at night) or if the attendees come
                  alone like at dating events to increase demand for GHS.
                </li>
                <li className="py-3">
                  Potential{" "}
                  <i className="text-[#03AF70]">
                    users need to find out about the app before{" "}
                  </i>
                  they go to the venue
                </li>{" "}
                <li className="py-3">
                  Partnerships with{" "}
                  <i className="text-[#03AF70]">
                    nightclubs help reach our potential users
                  </i>{" "}
                  before they arrive at the venue, when they are more receptive
                  and likely to try out a new service.
                </li>
                <li className="py-3">
                  Additionally, its very easy to generate content when running
                  these tests, we should take more videos and photos setting up.
                </li>
              </ul>
            </>
          }
          imgs={
            <div className="flex flex-row justify-between w-full">
              <img src={pr31} alt="pr11" className="w-full pl-3" />
              <img src={pr32} alt="pr11" className="w-full px-3" />
              <img src={pr33} alt="pr11" className="w-full px-3" />
              <img src={pr34} alt="pr11" className="w-full pr-3" />
            </div>
          }
        />
        {/* <hr
          style={{
            border: "none",
            borderBottom: "3px solid white",
            opacity: "100%",
          }}
          className=""
        /> */}
        <p className="p-10"></p>
      </div>
      <div className="bg-[#C140FF]">
        <p
          style={{
            color: "#000000",
            leadingTrim: "both",
            textEdge: "cap",
            fontFamily: "GT Flexa",
            fontVariationSettings: "'wght' 550, 'wdth' 0",
            fontSize: "150px",
            fontStyle: "normal",
            // fontWeight: "715",
            lineHeight: "78%" /* 117px */,
            // letterSpacing: "1.5px",
            textTransform: "uppercase",
          }}
          className="px-[85px] mt-16"
        >
          TECHNICAL ASSUMPTIONS
        </p>
        <ResearchToggleBig
          name="❶ We can provide rides without any drivers / cars"
          color="#000000"
          padding={false}
          content={
            <>
              <div className="flex  justify-between w-full">
                <img src={t1} alt="pr11" className="w-full p-3" />
              </div>
              <p style={explainStyle} className="text-black">
                <b>FREENOW CASE STUDY </b>
              </p>
              <ul style={explainStyle} className="text-black">
                <li className="py-3">
                  FreeNow is a ride provider in London that{" "}
                  <i className="text-white">
                    use their own drivers as well as other Private Hire
                    Vehicles, Local Taxis (Black Cabs in London)
                  </i>
                  , and they exist as an extra layer to current ride sharing
                  platforms. Rival ride providers enable this through their
                  developer portals and APIs (like Uber, Bolt, FreeNow and Black
                  Cabs). They know FreeNow's a competitor, but they are happy to
                  provide white label solutions to capture more of the users
                </li>

                <li className="py-3">
                  Will it take too long? Based on App Store Reviews (4.8/5),
                  overall,{" "}
                  <i className="text-white">
                    no reviews mention excessive waiting times
                  </i>
                  . Reviews mentioned: features that should be included like app
                  deletion (required by GDPR), UI functionality like recent
                  addresses, and lack of customer service.
                </li>
                <li className="py-3">
                  Gett also uses other ride platform's drivers
                </li>
              </ul>
            </>
          }
        />
        <ResearchToggleBig
          name="❷ We can create an algorithm to match compatible users"
          color="#000000"
          padding={false}
          content={
            <>
              <div className="flex  justify-between w-full">
                <img src={t2} alt="pr11" className="w-full pb-3" />
              </div>
              <p style={explainStyle} className="py-12 text-black">
                The matching algorithm is being designed by CEO Arturo Cano for
                his Masters at Imperial College London. We have consulted
                industry experts to validate the feasibility of our proprietary
                algorithm.
              </p>
              <img src={t3} alt="pr11" className="w-full py-8" />
              <img src={t4} alt="pr11" className="w-full py-6" />
            </>
          }
        />
      </div>
      <div className="bg-black">
        <p
          style={{
            color: "#1F5EFF",
            leadingTrim: "both",
            textEdge: "cap",
            fontFamily: "GT Flexa",
            fontVariationSettings: "'wght' 550, 'wdth' 0",
            fontSize: "150px",
            fontStyle: "normal",
            // fontWeight: "715",
            lineHeight: "78%" /* 117px */,
            // letterSpacing: "1.5px",
            textTransform: "uppercase",
          }}
          className="px-[85px] mt-16"
        >
          MARKET ASSUMPTIONS
        </p>
        <ResearchToggleBig
          name="❶ The ride sharing critical mass is obtainable"
          color="#03AF70"
          padding={false}
          content={
            <>
              <div className="flex  justify-between w-full">
                <img src={m1} alt="pr11" className="w-full py-3 mb-5" />
              </div>
              <div className="flex flex-row">
                <p style={explainStyle} className=" text-center px-3">
                  <b>LOTS OF POTENTIAL USERS </b>
                </p>
                <p style={explainStyle} className=" text-center px-3">
                  <b>LOW CUSTOMER ACQUISITION COSTS </b>
                </p>
                <p style={explainStyle} className="text-center px-3">
                  <b>
                    SIMILAR INITIAL ROUTES BETWEEN UNIVERSITY ACCOMODATIONS AND
                    VENUES
                  </b>
                </p>
              </div>
              <p style={explainStyle} className=" text-center pt-12">
                Critical mass is not that high if users are grown one atomic
                network at a time (<i>Andrew Chen’s Cold Start Theory</i>)
              </p>
            </>
          }
        />
        <ResearchToggleBig
          name="❷ External validation"
          color="#F74AFF"
          padding={false}
          content={
            <>
              <p style={explainStyle} className=" text px-3">
                <b>FROM OUR ADVISORS </b>
              </p>
              <div className="flex  justify-between w-full">
                <img src={m2} alt="pr11" className="w-full pb-3" />
              </div>

              <p style={explainStyle} className=" text px-3 pt-12">
                <b>AND 1 ON 1 CHATS WITH INDUSTRY EXPERTS</b>
              </p>
              <img src={m3} alt="pr11" className="w-full py-12" />
            </>
          }
        />
      </div>
      <FooterSection />
    </div>
  );
};

export default PartnersResearchPage;
