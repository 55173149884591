import React, { useEffect, useState } from "react";
import MissionPageImage from "../assets/img/mission_page.webp";
import MissionPageImageWeb from "../assets/img/mission_page_web.webp";

const HeroSection = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 1000);
  const [imageHeight, setImageHeight] = useState("auto");

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 1000);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    const handleImageLoad = () => {
      const img = new Image();
      img.src = isMobile ? MissionPageImage : MissionPageImageWeb;
      img.onload = () => {
        if (!isMobile && img.height > window.innerHeight) {
          setImageHeight("100%");
        }
      };
    };

    handleImageLoad();
  }, [isMobile]);

  const titleStyle = {
    color: "black",
    textAlign: "center",
    textIndent: "both",
    textEdge: "cap",
    fontFamily: "GT Flexa",
    fontSize: isMobile ? "90px" : "200px",
    fontVariationSettings: "'wght' 550, 'wdth' 0",
    fontStyle: "normal",
    // fontWeight: 715,
    lineHeight: "78%" /* 156px */,
    // letterSpacing: "-1px",
    textTransform: "uppercase",
    position: "absolute",
    top: "20%",
    zIndex: 1,
  };

  const containerStyle = {
    position: "relative",
    width: "100%",
    height: "100%",
  };

  const imageStyle = {
    position: "absolute",
    top: isMobile ? "auto" : "0",
    bottom: isMobile ? "0" : "auto",
    left: "0",
    width: "100%",
    height: imageHeight,
    objectFit: "cover",
  };

  return (
    <div
      className="vh-100 flex flex-column justify-between items-center w-100 h-100 p-16 bg-[#F7D64A]"
      style={containerStyle}
    >
      <p style={titleStyle}>OUR MISSION</p>
      <img
        src={isMobile ? MissionPageImage : MissionPageImageWeb}
        alt="Our Mission"
        style={imageStyle}
      />
    </div>
  );
};

export default HeroSection;
