import React, { useEffect } from "react";
import MenuButton from "../components/MenuButton";
import HeroSection from "../components/HeroSection";
import VideoSection from "../components/VideoSection";
import PartnersSlider from "../components/PartnersSlider";
// import OtherSection from "../components/OtherSection";
import FooterSection from "../components/FooterSection";
import DescriptionSection from "../components/DescriptionSection";
import TestimonialsSection from "../components/TestimonialsSection";
import TextScroll from "../components/TextScrollSection";
// import Navbar from "../components/NavBarNoChange";

const LandingPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top when component mounts
  }, []); // Empty dependency array ensures the effect runs only once when mounted

  return (
    <div className="flex-col w-full">
      {/* <Navbar /> */}
      <MenuButton />
      <HeroSection />
      <VideoSection />
      <DescriptionSection />
      <PartnersSlider />
      <TestimonialsSection />
      <TextScroll />
      {/* <OtherSection title="Why Choose Us?" content="Lorem ipsum..." /> */}
      {/* <OtherSection title="Our Services" content="Donec sollicitudin..." />
      <OtherSection title="Testimonials" content="Praesent sapien massa..." />
      <OtherSection title="Contact Us" content="Quisque velit nisi..." /> */}
      <FooterSection className="flex" />
    </div>
  );
};

export default LandingPage;
