import React, { useEffect, useRef, useState } from "react";
import MenuButton from "../components/MenuButton";
import PartnersButtonRow from "../components/PartnersButtonRow";
import ToggleSectionBig from "../components/ToggleSectionBig";
import FooterSection from "../components/FooterSection";

// import stickers from "../assets/img/stickers.svg";
import usp from "../assets/img/usp.webp";

import marketsize from "../assets/img/marketSize.svg";
import marketsizetext from "../assets/img/marketsizingtext.svg";
import FAQSubSection from "../components/FAQSubSection";

import comp1 from "../assets/img/competitor1.svg";
import comp2 from "../assets/img/competitor2.svg";

import r1 from "../assets/img/r1.svg";
import r2 from "../assets/img/r2.svg";
import r3 from "../assets/img/r3.svg";

import partnershipsmap from "../assets/img/partnershipsmap.webp";
import pamela from "../assets/img/pamelalyddon.webp";
import kate from "../assets/img/katefanning.png";
import pelin from "../assets/img/pelinliu.png";

import ipsvg from "../assets/img/ip123.webp";
import kieran from "../assets/img/kieran.png";
import emma from "../assets/img/emma.png";
import adodja from "../assets/img/adodja.png";

const PartnersBusinessPage = () => {
  const [isMobile, setIsMobile] = useState(false);
  const [menuButtonHeight, setMenuButtonHeight] = useState(0);
  const [scrolled, setScrolled] = useState(false);
  const partnersButtonRef = useRef(null);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 710);
    };

    // Initial check on component mount
    handleResize();

    // Event listener for window resize
    window.addEventListener("resize", handleResize);

    // Cleanup on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    // Calculate the height of the MenuButton component
    const menuButton = document.getElementById("menuButton");
    if (menuButton) {
      const height = menuButton.offsetHeight;
      setMenuButtonHeight(height);
    }
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      const partnersButtonPosition =
        partnersButtonRef.current.getBoundingClientRect();
      if (partnersButtonPosition.top <= -50) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const USPContent = (
    <img src={usp} alt="usp" />
    // <div className="flex flex-row">
    //   <img className="flex-2" src={stickers} alt="stickers" />
    //   <div className="flex-5">
    //     <p
    //       style={{
    //         color: "var(--BRAND-COLOURS-ONYX, #070706)",
    //         leadingTrim: "both",
    //         textEdge: "cap",
    //         fontFamily: "KHTeka",
    //         fontSize: "36px",
    //         fontStyle: "normal",
    //         fontWeight: "400",
    //         lineHeight: "normal",
    //         letterSpacing: "-0.36px",
    //         // textTransform: "uppercase",
    //       }}
    //     >
    //       TO DO ADD ITALICS
    //       <br />
    //       <br />
    //       <br />
    //       GET HOME SAFE stands out from other ride-pooling services due to our{" "}
    //       <i>unique matching algorithm</i> which considers more factors than
    //       just the similarity of routes. We offer filters like “
    //       <i>women-only</i>”, “<i>university peers</i>”, or “
    //       <i>mutual friends</i>”. After users get their ID verified, they will
    //       only be shown co-riders that match their safety preferences ensuring
    //       they are matched with people that will make them feel comfortable. For
    //       women, the default option will be “women-only” to ensure enhanced
    //       feelings of safety. However, this will be easy to change to have
    //       access to a wider pool of co-riders.
    //       <br />
    //       <br />
    //       In contrast to Uber Pool, users meet up at one specific pick-up point
    //       before ordering the ride. All co-riders must consent via the app to
    //       travelling together and no one can join without all co-riders’
    //       agreement. Our review and report system also promotes a safe
    //       environment where people can trust the co-riders with whom they share
    //       rides.
    //       <br />
    //       <br />
    //       What differentiates us from other safety apps is that we focus on both
    //       preventative safety and savings. Current safety apps only react when
    //       there's already a problem. Our app aims to prevent threats by
    //       connecting trusted members of our community to travel together. It’s
    //       not just about getting from A to B; it's about supporting each other,
    //       making travel at night safer and more affordable for everyone.
    //     </p>
    //   </div>
    // </div>
  );

  const MarketSizeContent = (
    <div className="justify-center mx-auto">
      <img src={marketsize} alt="market size" className="self-center mx-auto" />
      <FAQSubSection
        qna={[
          {
            q: "How did we get these numbers?",
            a: (
              <div className="flex flex-row">
                <img src={marketsizetext} alt="market size" />
              </div>
            ),
          },
        ]}
        primary={""}
        secondary={"#C140FF"}
      />
    </div>
  );

  const CompetitorContent = (
    <div className="flex flex-col w-full">
      <img src={comp1} alt="competitor 1" className="self-center mx-auto" />
      <img src={comp2} alt="competitor 1" className="self-center mx-auto" />
    </div>
  );

  const BusinessContent = (
    <div className="flex flex-col w-full">
      <img src={r1} alt="risk 1" className="self-center mx-auto my-2" />
      <img src={r2} alt="risk 2" className="self-center mx-auto my-2" />
      <img src={r3} alt="risk 3" className="self-center mx-auto my-2" />
    </div>
  );

  const partnerNameStyle = {
    color: "white",
    leadingTrim: "both",
    textEdge: "cap",
    fontFamily: "GT Flexa",
    fontVariationSettings: "'wght' 550, 'wdth' 0",
    fontSize: "64px",
    fontStyle: "normal",
    // fontWeight: "715",
    lineHeight: "78%", // 49.92px
    // letterSpacing: "-0.64px",
    textTransform: "uppercase",
  };

  const PartnershipsContent = (
    <div className="flex flex-col w-full">
      <p className="text-[#FEDB01] text-center">
        At Get Home Safe, we believe in the power of collaboration. We're in
        active discussions with various stakeholders that each bring a unique
        value to our mission. These partnerships will help us expand our user
        base and increase trust in our service. They are also essential for us
        to pilot our platform in key venues. Our social mission is the driving
        force behind these partnerships.
      </p>
      <img
        src={partnershipsmap}
        alt="Partnerships Logos"
        className="self-center mx-auto"
      />
      <p
        className="pt-5"
        style={{
          color: "white",
          leadindTrim: "both",
          textEdge: "cap",
          fontFamily: "GT Flexa",
          fontSize: "150px",
          fontVariationSettings: "'wght' 550, 'wdth' 0",
          fontStyle: "normal",
          // fontEeight: 715,
          lineHeight: "78%" /* 117px */,
          // letterSpacing: "-1.5px",
          textTransform: "uppercase",
        }}
      >
        BOARD OF ADVISORS
      </p>
      <div className="flex flex-row">
        <div className="flex flex-col w-full items-center p-16">
          <img alt="pamela" src={pamela} />
          <p className="pt-3" style={partnerNameStyle}>
            Pamela Lyddon
          </p>
          <p className="text-center">
            Creator of SaferTogether campaign and member of nightcap
          </p>
        </div>
        <div className="flex flex-col w-full items-center p-16">
          <img alt="kate" src={kate} />
          <p className="pt-3" style={partnerNameStyle}>
            Kate Fanning
          </p>
          <p className="text-center">Design Research Lead at Kingfishers</p>
        </div>
        <div className="flex flex-col w-full items-center p-16">
          <img alt="pelin" src={pelin} />
          <p className="pt-3" style={partnerNameStyle}>
            PELIN DEMIREL LIU
          </p>
          <p className="text-center">
            Senior Lecturer in innovation & Enterprise at Imperial College
            London
          </p>
        </div>
      </div>
    </div>
  );

  const IPContent = (
    <div className="flex flex-col w-full">
      <img src={ipsvg} alt="IP" className="self-center mx-auto pb-10" />
      <p className="text-center text-black">
        THIS WAS ALL VALIDATED THROUGH DISCUSSIONS WITH THE FOLLOWING IP LAWYERS
      </p>
      <div className="flex flex-row">
        <div className="flex flex-col items-center p-16">
          <img alt="adodja" src={adodja} />
          <p className="pt-3" style={partnerNameStyle}>
            Adjoa Anim
          </p>
        </div>
        <div className="flex flex-col items-center p-16">
          <img alt="kieran" src={kieran} />
          <p className="pt-3" style={partnerNameStyle}>
            Kieran John
          </p>
        </div>
        <div className="flex flex-col items-center p-16">
          <img alt="emma" src={emma} />
          <p className="pt-3" style={partnerNameStyle}>
            Emma Miller
          </p>
        </div>
      </div>
    </div>
  );

  return (
    <div className="flex flex-col bg-[#FEDB01]">
      {!scrolled && <MenuButton className="" />}
      {scrolled && (
        <div className="sticky top-0 z-50 mx-16 pt-8">
          <PartnersButtonRow />
        </div>
      )}
      <div className={`h-full w-full `}>
        <div
          style={{ marginTop: menuButtonHeight }}
          className={`mb-5 ${isMobile ? "px-3" : "px-16"}`}
          ref={partnersButtonRef}
        >
          <PartnersButtonRow />
        </div>
        <ToggleSectionBig
          color="#FEDB01"
          name="USP"
          scolor="#070706"
          content={USPContent}
        />
        <ToggleSectionBig
          scolor="#FFFFFF"
          color="#000000"
          name="MARKET SIZE"
          content={MarketSizeContent}
        />
        <ToggleSectionBig
          scolor="#000000"
          color="#C140FF"
          name="COMPETITOR LANDSCAPE"
          content={CompetitorContent}
        />{" "}
        <ToggleSectionBig
          scolor="#FFFFFF"
          color="#1F5EFF"
          name="RISK AND MITIGATION"
          content={BusinessContent}
        />{" "}
        <ToggleSectionBig
          scolor="#FFFFFF"
          color="#000000"
          name="PARTNERSHIPS"
          content={PartnershipsContent}
        />{" "}
        <ToggleSectionBig
          scolor="#000000"
          color="#C140FF"
          name="IP"
          content={IPContent}
        />
        <FooterSection />
      </div>
    </div>
  );
};

export default PartnersBusinessPage;
