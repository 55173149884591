// import React from "react";
import React, { useEffect, useState } from "react";
// import Phases from "../assets/img/phases.svg";

import Theo from "../assets/img/Theo.webp";
import LudoM from "../assets/img/Ludom.webp";
import Kiran from "../assets/img/Kiran.webp";
import LudoF from "../assets/img/Ludof.webp";
import Arturo from "../assets/img/Arturo.webp";

const team = [
  {
    name: "Ludo M",
    image: LudoM,
    role: "CMO",
    text: "Social media expert essential for our outreach initiatives",
    color: "#F74AFF",
  },
  {
    name: "Arturo",
    image: Arturo,
    role: "CEO",
    text: "Developing strategy and ride pooling algorithm",
    color: "#C140FF",
  },
  {
    name: "Ludo F",
    image: LudoF,
    role: "CSO",
    text: " Widespread connections in the nightlife industry ",
    color: "#FEDB01",
  },
  {
    name: "Theo",
    image: Theo,
    role: "CTO",
    text: "Leading app development",
    color: "#03AF70",
  },

  {
    name: "Kiran",
    image: Kiran,
    role: "CCO",
    text: "Award winning designer",
    color: "#1F5EFF",
  },
];

const TeamSection = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 1000);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 1000);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const bigStyle = {
    color: "var(--BRAND-COLOURS-OFFWHITE, #F4F1E9)",
    textTrim: "both",
    textEdge: "cap",
    fontFamily: "GT Flexa",
    fontSize: isMobile ? "75px" : "150px",
    fontStyle: "normal",
    fontVariationSettings: "'wght' 550, 'wdth' 0",
    // fontWeight: "715",
    lineHeight: "78%" /* 117px */,
    textTransform: "uppercase",
  };

  const smallStyle = {
    color: "var(--BRAND-COLOURS-OFFWHITE, #F4F1E9)",
    textTrim: "both",
    textEdge: "cap",
    fontFamily: "KHTeka",
    fontSize: isMobile ? "18px" : "24px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "100%" /* 24px */,
    letterSpacing: "-0.24px",
  };

  const nameStyle = {
    textTrim: "both",
    textEdge: "cap",
    fontFamily: "GT Flexa",
    fontSize: isMobile ? "68px" : "100px",
    // fontSize: "101.302px",
    fontVariationSettings: "'wght' 550, 'wdth' 0",
    fontStyle: "normal",
    // fontWeight: "715",
    lineHeight: "78%" /* 99.276px */,
    textTransform: "uppercase",
  };

  const roleStyle = {
    color: "var(--BRAND-COLOURS-OFFWHITE, #F4F1E9)",
    textAlign: "center",
    textTrim: "both",
    textEdge: "cap",
    fontFamily: "KHTeka",
    fontSize: isMobile ? "26px" : "39px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "78%" /* 29.998px */,
    textTransform: "uppercase",
  };

  return (
    <div className="w-full pt-20" id="team-section">
      <div
        className={`flex  items-center ${
          isMobile ? "flex-col px-3" : "flex-row px-32"
        }`}
      >
        <p
          style={bigStyle}
          className={`flex flex-3 ${isMobile ? "" : "mr-10"}`}
        >
          MEET THE TEAM
        </p>
        <p
          style={smallStyle}
          className={`flex flex-1 ${isMobile ? "text-center" : "text-start"}`}
        >
          5 Design Engineers from
          <br /> Imperial College London
        </p>
      </div>
      {/* Iterate through the team array and display each member */}
      <div
        className={`flex  w-full ${isMobile ? "flex-col " : "flex-row px-32"} `}
      >
        {team.map((member, index) => (
          <div key={index} className="flex flex-col items-center p-3  w-full">
            <p style={{ ...nameStyle, color: member.color }}>{member.name}</p>
            <img src={member.image} alt={member.name} className="pb-3 w-full" />
            <p style={roleStyle}>{member.role}</p>
            <p style={smallStyle} className="text-center">
              {member.text}
            </p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default TeamSection;
