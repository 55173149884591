import React, { useEffect, useState } from "react";
// import toggle from "../assets/img/toggle.svg";

const Triangle = ({ color, style }) => (
  <svg
    width="102"
    height="89"
    viewBox="0 0 102 89"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={style}
  >
    <path
      d="M55.3355 86.453C53.4134 89.7988 48.5865 89.7988 46.6645 86.453L1.30312 7.4906C-0.611763 4.15728 1.79446 -9.02252e-06 5.63866 -8.68645e-06L96.3614 -7.55214e-07C100.206 -4.19144e-07 102.612 4.1573 100.697 7.49062L55.3355 86.453Z"
      fill={color}
    />
  </svg>
);

const ToggleSectionBig = ({ color, name, content, scolor }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 1000);
    };

    // Initial check on component mount
    handleResize();

    // Event listener for window resize
    window.addEventListener("resize", handleResize);

    // Cleanup on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const toggleSection = () => {
    setIsExpanded(!isExpanded);
  };

  const titleStyle = {
    leadingTrim: "both",
    textEdge: "cap",
    fontFamily: '"GT Flexa"',
    fontSize: "150px",
    fontVariationSettings: "'wght' 550, 'wdth' 0",
    fontStyle: "normal",
    // fontWeight: "715",
    lineHeight: "78%", // 117px at 150px font size
    textTransform: "uppercase",
    color: scolor,
    // colorText: scolor,
    // fontWeight: "bold",
  };

  const triangleStyle = {
    transform: isExpanded ? "rotate(0deg)" : "rotate(-90deg)",
    transition: "transform 0.3s ease", // Optional: Add a transition effect
  };

  return (
    <div
      className={`w-full pt-10 justify-center ${isMobile ? "px-3" : "px-16"} `}
      style={{
        backgroundColor: color,
      }}
    >
      <div
        className="px-6 py-4 cursor-pointer flex flex-row justify-between items-center"
        onClick={toggleSection}
      >
        <h1 className=" text-5xl font-bold" style={titleStyle}>
          {name}
        </h1>
        <Triangle color={scolor} style={triangleStyle} />
      </div>
      {isExpanded && (
        <div className="px-6 py-4 mx-auto justify-center">{content}</div>
      )}
    </div>
  );
};

export default ToggleSectionBig;
