import React, { useEffect, useState } from "react";
import "./TextScrollSection.css";

const TextScroll = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 700);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 1000);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const textStyle = {
    color: "var(--white, #FFF)",
    textAlign: "center",
    textTrim: "both",
    textEdge: "cap",
    fontFamily: "GT Flexa",
    fontSize: isMobile ? "100px" : "200px",
    fontStyle: "normal",
    // fontWeight: "715",
    lineHeight: "78%" /* 234px */,
    // letterSpacing: isMobile ? "0px" : "-1px",
    fontVariationSettings: "'wght' 550, 'wdth' 0",
    textTransform: "uppercase",
    whiteSpace: "nowrap",
    animation: "scroll 60s linear infinite", // Adjust the duration as needed
  };
  return (
    <div style={{ overflow: "hidden" }} className="bg-black pt-10">
      <p style={textStyle}>
        enough is enough! safer together! enough is enough! safer together!
        enough is enough! safer together! enough is enough! safer together!
        enough is enough! safer together! enough is enough! safer together!
        enough is enough! safer together! enough is enough! safer together!
        enough is enough! safer together! enough is enough! safer together!
        enough is enough! safer together! enough is enough! safer together!
        enough is enough! safer together! enough is enough! safer together!
        enough is enough! safer together! enough is enough! safer together!
        enough is enough! safer together! enough is enough! safer together!
      </p>
    </div>
  );
};

export default TextScroll;
