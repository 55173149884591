import React from "react";
import c1 from "../assets/img/costs1.svg";
import c2 from "../assets/img/costs2.webp";

const CostsSection = () => {
  // const [isMobile, setIsMobile] = useState(window.innerWidth < 700);

  // useEffect(() => {
  //   const handleResize = () => {
  //     setIsMobile(window.innerWidth < 1000);
  //   };

  //   window.addEventListener("resize", handleResize);

  //   return () => {
  //     window.removeEventListener("resize", handleResize);
  //   };
  // }, []);

  return (
    <div className="h-full w-full bg-[#C140FF] px-16">
      <img src={c1} alt="c1" className="h-full my-12 w-1/2" />
      <img src={c2} alt="c2" className="w-full h-full mb-24" />
    </div>
  );
};

export default CostsSection;
