import React, { useEffect, useRef, useState } from "react";
import MenuButton from "../components/MenuButton";
import PartnersButtonRow from "../components/PartnersButtonRow";
import FooterSection from "../components/FooterSection";

import i1 from "../assets/img/product0.webp";
import i2 from "../assets/img/product1.png";
import i3 from "../assets/img/product3.png";
import i4 from "../assets/img/product4.png";
import i5 from "../assets/img/product5.png";
import i6 from "../assets/img/product6.png";
import i7 from "../assets/img/product7.png";
import android from "../assets/img/googleplaystore.png";

const PartnersProductPage = () => {
  const [isMobile, setIsMobile] = useState(false);
  const [menuButtonHeight, setMenuButtonHeight] = useState(0);
  const [scrolled, setScrolled] = useState(false);
  const partnersButtonRef = useRef(null);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 710);
    };

    // Initial check on component mount
    handleResize();

    // Event listener for window resize
    window.addEventListener("resize", handleResize);

    // Cleanup on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    // Calculate the height of the MenuButton component
    const menuButton = document.getElementById("menuButton");
    if (menuButton) {
      const height = menuButton.offsetHeight;
      setMenuButtonHeight(height);
    }
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      const partnersButtonPosition =
        partnersButtonRef.current.getBoundingClientRect();
      if (partnersButtonPosition.top <= -50) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  const imageStyle = {
    position: "absolute",
    top: isMobile ? "auto" : "0",
    bottom: isMobile ? "0" : "auto",
    left: "0",
    width: "100%",
    height: "auto",
    objectFit: "cover",
  };
  const containerStyle = {
    position: "relative",
    width: "100%",
    height: "100%",
  };

  return (
    <div className="flex flex-col bg-[#C140FF]">
      {!scrolled && <MenuButton className="" />}
      {scrolled && (
        <div className="sticky top-0 z-50 mx-16 pt-8">
          <PartnersButtonRow />
        </div>
      )}
      <div className={` w-full `}>
        <div
          style={{ marginTop: menuButtonHeight }}
          className={`mb-5 h-full `}
          ref={partnersButtonRef}
        >
          <div className="px-16">
            <PartnersButtonRow />
          </div>
          <div
            className="vh-100 flex flex-column justify-between items-center w-100 h-100"
            style={containerStyle}
          >
            <img src={i1} alt="Product " style={imageStyle} />
          </div>
        </div>
        <div className="bg-black self-center items-center ">
          <div className="w-3/4 mx-auto pt-32">
            <img src={i3} alt="Product " className="px-16 mb--10" />
            <img src={i4} alt="Product " className="px-16" />
            <img src={i5} alt="Product " className="px-16" />
            <img src={i6} alt="Product " className="px-16" />
            <img src={i7} alt="Product " className="px-16" />
          </div>
        </div>
        <FooterSection />
      </div>
      <div className="flex flex-row justify-center items-center fixed bottom-10 left-1/2 transform -translate-x-1/2 space-x-4">
        <button
          onClick={() =>
            window.open(
              "https://apps.apple.com/us/app/get-home-safe/id6473676583",
              "_blank"
            )
          }
        >
          <img src={i2} alt="download ios" className="h-16" />
        </button>
        <button
          className="bg-black border border-white rounded-lg"
          onClick={() =>
            window.open(
              "https://play.google.com/store/apps/details?id=io.gethomesafe.reactnative",
              "_blank"
            )
          }
        >
          <img src={android} alt="download android" className="h-16" />
        </button>
      </div>
    </div>
  );
};

export default PartnersProductPage;
