import firebase from "firebase/compat/app";
import React, { useEffect } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from "react-router-dom";

import LandingPage from "./pages/LandingPage";
import MissionPage from "./pages/MissionPage";
import PartnersPage from "./pages/PartnersPage";
import PartnersBusinessPage from "./pages/PartnersBusinessPage";
import PartnersResearchPage from "./pages/PartnersResearchPage";
import SignInPage from "./pages/SignInPage";
import PrivacyPolicyPage from "./pages/PrivacyPolicyPage";
import FAQsPage from "./pages/FAQsPage";
import VerifyEmailPage from "./pages/app_logic/VerifyEmailPage";

import "./assets/fonts.css";
import PartnersProductPage from "./pages/PartnersProductPage";
import DeleteAccountPage from "./pages/app_logic/DeleteAccountPage";
import { realTimeDB } from "./firebase/config";

// Function to update the counter and track the referrer in Realtime Database
const updateCounter = (path, referrer) => {
  const counterRef = realTimeDB.ref("origins"); // Reference to the 'from' counter in the database
  const newEntryRef = counterRef.push(); // Create a new entry in the 'from' counter

  newEntryRef
    .set({
      path: path,
      referrer: referrer,
      timestamp: firebase.database.ServerValue.TIMESTAMP,
    })
    .then(() => {
      console.log("Path and referrer tracked successfully.");
    })
    .catch((error) => {
      console.error("Error tracking path and referrer:", error);
    });
};

const RequireAuth = ({ children }) => {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    console.log("location", location.pathname);
    const referrer = document.referrer || "Direct Access"; // Get referrer or set "Direct Access" if none

    if (location.pathname.startsWith("/Partners")) {
      const signedIn = sessionStorage.getItem("signedInPartners");
      // If user is not signed in, redirect to SignIn page
      if (!signedIn || signedIn === "false") {
        navigate("/Partners/SignIn");
      }
    } else if (location.pathname === "/App/DeleteAccount") {
      // Specific logic for DeleteAccountPage (if any)
    } else if (location.pathname.startsWith("/from")) {
      console.log("test");
      updateCounter(location.pathname, referrer); // Track path and referrer
      navigate("/");
    } else if (
      location.pathname.startsWith("/Mission") ||
      location.pathname.startsWith("/FAQs")
    ) {
      console.log("nothing");
    } else {
      navigate("/");
    }
    console.log("Outside if else");
    // eslint-disable-next-line
  }, [location.pathname]);

  // If the username condition is met or on allowed pages, render children
  return children;
};

const AppInner = () => {
  return (
    <div className="app-container">
      <Routes>
        <Route path="/" element={<LandingPage />} index />
        <Route path="/Landing" element={<LandingPage />} />
        <Route path="/Mission" element={<MissionPage />} />
        <Route path="/Partners/" element={<SignInPage />} />
        <Route path="/Partners/Home" element={<PartnersPage />} />
        <Route path="/Partners/Business" element={<PartnersBusinessPage />} />
        <Route path="/Partners/Research" element={<PartnersResearchPage />} />
        <Route path="/Partners/Product" element={<PartnersProductPage />} />
        <Route path="/Partners/SignIn" element={<SignInPage />} />
        <Route path="/PrivacyPolicy" element={<PrivacyPolicyPage />} />
        <Route path="/FAQs" element={<FAQsPage />} />
        <Route path="/contact" element={<FAQsPage />} />
        <Route path="/App/VerifyEmail" element={<VerifyEmailPage />} />
        <Route path="/App/DeleteAccount" element={<DeleteAccountPage />} />
      </Routes>
    </div>
  );
};

const App = () => {
  return (
    <Router>
      <RequireAuth>
        <AppInner />
      </RequireAuth>
    </Router>
  );
};

export default App;
