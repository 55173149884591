import React, { useState, useEffect } from "react";

import m1 from "../assets/img/marketingtitle.svg";
import m2 from "../assets/img/marketing2.webp";
import m3 from "../assets/img/marketing3.svg";
import m4 from "../assets/img/marketing4.png";
import m5 from "../assets/img/marketing5.svg";
import m6 from "../assets/img/marketing6.webp";
import m7 from "../assets/img/marketing7.svg";
import m8 from "../assets/img/marketing8.png";

const MarketingSection = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 700);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 1000);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div className="h-full w-full py-12 bg-black px-16">
      <img src={isMobile ? m1 : m1} alt="m1" className="w-full mb-24" />
      <img src={isMobile ? m2 : m2} alt="m2" className="w-full mb-24" />
      <img src={isMobile ? m3 : m3} alt="m3" className="w-full mb-12" />
      <img src={isMobile ? m4 : m4} alt="m4" className="w-1/2 mx-auto mb-24" />
      <img src={isMobile ? m5 : m5} alt="m5" className="w-full mb-12" />
      <img src={isMobile ? m6 : m6} alt="m6" className="mb-24 mx-auto w-3/4" />
      <img src={isMobile ? m7 : m7} alt="m7" className="w-full mb-12" />
      <img src={isMobile ? m8 : m8} alt="m8" className="w-3/4 mb-24 mx-auto" />
    </div>
  );
};

export default MarketingSection;
