import React, { useState } from "react";
import { realTimeDB } from "../../firebase/config";

const DeleteAccountPage = () => {
  const [email, setEmail] = useState(""); // State for email address

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      // Push email address to the database under a 'deletedAccounts' node
      await realTimeDB.ref("/accountsDeletionRequests").push(email);
      // Reset email state after submission
      setEmail("");
      alert("Account deletion request submitted successfully.");
    } catch (error) {
      console.error("Error adding email to deleted accounts:", error);
      alert("An error occurred. Please try again later.");
    }
  };

  return (
    <div className="flex flex-col items-center justify-center h-screen">
      <h1 className="text-2xl font-bold">Delete Account Request</h1>
      <p className="text mb-4 text-black">
        The request might take a week to be taken into account
      </p>
      <form onSubmit={handleSubmit} className="flex flex-col items-center">
        <input
          type="email"
          placeholder="Enter email address"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          className="border border-gray-300 rounded-md px-4 py-2 mb-4 focus:outline-none focus:border-blue-500"
        />
        <button
          type="submit"
          className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded-md focus:outline-none focus:shadow-outline"
        >
          Delete Account
        </button>
      </form>
    </div>
  );
};

export default DeleteAccountPage;
