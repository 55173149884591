import React, { useEffect, useState } from "react";
import vidImg from "../assets/img/video_img.webp";
import vidImgMobile from "../assets/img/video_img_mobile.webp";

const VideoSection = () => {
  const [showOverlay, setShowOverlay] = useState(true);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 1000);
  let initialWidth = window.innerWidth - (isMobile ? 50 : 200); // Adjust padding as needed
  initialWidth = initialWidth > 1000 ? 1000 : initialWidth; // Set max width
  const aspectRatio = 16 / 9; // YouTube video aspect ratio
  let initialHeight =
    initialWidth / aspectRatio > window.innerHeight
      ? window.innerHeight - (isMobile ? 100 : 200)
      : initialWidth / aspectRatio;

  const [playerSize, setPlayerSize] = useState({
    width: initialWidth,
    height: initialHeight,
  });

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 1000);
      let width = window.innerWidth - (isMobile ? 50 : 200); // Adjust padding as needed
      width = width > 1000 ? 1000 : width; // Set max width
      const height =
        width / aspectRatio > window.innerHeight
          ? window.innerHeight - (isMobile ? 100 : 200)
          : width / aspectRatio;
      setPlayerSize({ width, height });
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
    // eslint-disable-next-line
  }, [isMobile]);

  const handleOverlayClick = () => {
    setShowOverlay(false);
  };

  return (
    <div className="video-container relative flex justify-center items-center  bg-[#C140FF] min-h-[400px] h-screen">
      {showOverlay && (
        <div
          className="overlay absolute top-0 left-0 right-0 bottom-0 flex flex-col justify-center items-center cursor-pointer bg-[#C140FF]"
          onClick={handleOverlayClick}
          style={{
            backgroundImage: isMobile
              ? `url(${vidImgMobile})`
              : `url(${vidImg})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        >
          <p
            className="text-black text-center uppercase leading-none tracking-tighter"
            style={{
              fontFamily: "GT Flexa",
              textEdge: "cap",
              fontSize: isMobile ? "96px" : "150px",
              fontVariationSettings: "'wght' 550, 'wdth' 0",
              letterSpacing: "0px",
              lineHeight: "78%",
            }}
          >
            HOW DOES IT WORK?
          </p>
          <p
            className="text-black text-center uppercase leading-none tracking-tighter p-3 m-0 border-black border-3"
            style={{ fontFamily: "KHTeka", fontWeight: 715 }}
          >
            Watch the Video
          </p>
        </div>
      )}

      <div
        id="player"
        style={{ width: playerSize.width, height: playerSize.height }}
        className={showOverlay ? "hidden" : ""}
      >
        <iframe
          title="video"
          src="https://player.vimeo.com/video/920064172?h=73a9d29f60&autoplay=0&color=ffffff"
          style={{
            width: "100%",
            height: "100%",
          }}
          allow="fullscreen; picture-in-picture"
          allowFullScreen
        ></iframe>
      </div>
    </div>
  );
};

export default VideoSection;
