import React, { useEffect, useState } from "react";
import Phases from "../assets/img/phases.webp";
import PhasesMobile from "../assets/img/phases_mobile.webp";

const PhasesSection = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 1000);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 1000);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div className="w-full">
      <img
        src={isMobile ? PhasesMobile : Phases}
        alt="Phases"
        className="w-full"
      />
    </div>
  );
};

export default PhasesSection;
