import React, { useEffect, useState, useRef } from "react";
import MenuButton from "../components/MenuButton";

import heroImg from "../assets/img/partners_media.webp";
import heroText from "../assets/img/partners_media.svg";

// import arrowleft from "../assets/img/arrowleft.svg";
// import arrowright from "../assets/img/arrowright.svg";

import megaphone from "../assets/img/megaphone.svg";
import evileye from "../assets/img/evileye.svg";
import strength from "../assets/img/strength.svg";
import PartnersButtonRow from "../components/PartnersButtonRow";
import RevenueStreams from "../components/RevenueStreams";
import CostsSection from "../components/CostsSection";
import MarketingSection from "../components/MarketingSection";
import FooterSection from "../components/FooterSection";

import financialReport from "../assets/doc/financial_report.pdf";
import onePager from "../assets/doc/one_pager.pdf";

const LandingPage = () => {
  const [isMobile, setIsMobile] = useState(false);
  const [menuButtonHeight, setMenuButtonHeight] = useState(0);
  const [scrolled, setScrolled] = useState(false);
  const partnersButtonRef = useRef(null);

  // Updated function to open PDF in new tab
  const handleOpenPdf = () => {
    window.open(financialReport, "_blank");
  };

  const handleOpenVideoPitch = () => {
    window.open("https://www.youtube.com/watch?v=L7gbZ-flrLs", "_blank");
  };

  const handleOpenOnePager = () => {
    window.open(onePager, "_blank");
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 710);
    };

    // Initial check on component mount
    handleResize();

    // Event listener for window resize
    window.addEventListener("resize", handleResize);

    // Cleanup on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    // Calculate the height of the MenuButton component
    const menuButton = document.getElementById("menuButton");
    if (menuButton) {
      const height = menuButton.offsetHeight;
      setMenuButtonHeight(height);
    }
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      const partnersButtonPosition =
        partnersButtonRef.current.getBoundingClientRect();
      if (partnersButtonPosition.top <= -50) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const buttonTextStyle = {
    color: "var(--BRAND-COLOURS-ONYX, #070706)",
    textAlign: "center",
    lineHeight: "78%", // Corresponds to 62.4px at 80px font size
    fontFamily: "GT Flexa",
    fontSize: "42px",
    fontVariationSettings: "'wght' 550, 'wdth' 0",
    fontStyle: "normal",
    // fontWeight: 715,
    textDecorationLine: "underline",
    // letterSpacing: "0.02em",
    textTransform: "uppercase",
  };

  return (
    <div className="flex flex-col relative">
      {!scrolled && <MenuButton className={``} />}
      {scrolled && (
        <div className="sticky top-0 z-50 mx-16 pt-8">
          <PartnersButtonRow />
        </div>
      )}
      <div
        className={`vh-100 flex bg-white flex-column justify-between align-items-center w-full ${
          isMobile ? "p-3" : "px-16"
        } bg-black`}
        style={{
          backgroundImage: `url(${heroImg})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <img
          src={heroText}
          alt="Partners Sign In Text"
          className="w-1/3 mb-5"
          style={{ marginTop: menuButtonHeight }}
        />
        <div ref={partnersButtonRef} className={`w-full`}>
          <PartnersButtonRow />
        </div>
        <div className={`flex flex-col items-center p-4 rounded-lg w-full`}>
          <div className="flex flex-row justify-between w-full pb-10 items-end">
            <p></p>
            <button
              className=""
              style={buttonTextStyle}
              onClick={handleOpenVideoPitch}
            >
              <img
                src={megaphone}
                alt="video pitch"
                className="self-center mx-auto mb-2  h-32"
              />
              Video Pitch
            </button>
            <button
              className=""
              style={buttonTextStyle}
              onClick={handleOpenPdf}
            >
              <img
                src={evileye}
                alt="video pitch"
                className="self-center mx-auto mb-2  h-24"
              />
              Financial Report
            </button>
            <button
              className=""
              style={buttonTextStyle}
              onClick={handleOpenOnePager}
            >
              <img
                src={strength}
                alt="video pitch"
                className="self-center mx-auto mb-2 h-32"
              />
              One Pager
            </button>
            <p></p>
          </div>
        </div>
      </div>

      <RevenueStreams />
      <CostsSection />
      <MarketingSection />
      <FooterSection />

      {/* {showPdfModal && (
        <div className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 flex justify-center items-center z-50">
          <div className="bg-white p-4 rounded-lg">
            <button
              onClick={handleClosePdfModal}
              className="absolute top-4 right-4 text-gray-500 hover:text-gray-800"
            >
              Close
            </button>
            <iframe
              src="./assets/doc/financial_report.pdf"
              title="Financial Report"
              width="100%"
              // height="500px"
            ></iframe>
          </div>
        </div>
      )} */}
    </div>
  );
};

export default LandingPage;
