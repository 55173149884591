import React, { useEffect, useState } from "react";

const SisterhoodSection = ({ mt = 100 }) => {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 1000);
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 1000);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <div
      className={`relative items-center text-center text-lg my-20  bg-black bg-opacity-25 ${
        isMobile ? "px-3" : "px-32"
      }`}
      style={{ marginTop: `${150}px` }}
    >
      <p>
        For example, women look out for each other by sitting close on public
        transport to feel safer. We are on a mission to transform this
        sisterhood into a tangible platform that offers an affordable and
        reliable way to get home safe.
      </p>
    </div>
  );
};

export default SisterhoodSection;
