import React, { useEffect, useState } from "react";
import reactiveImg from "../assets/img/REACTIVE.svg";
import preventativeImg from "../assets/img/PREVENTATIVE.svg";

const SafetyAppsSection = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 1000);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 1000);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <div className="w-full">
      <div
        className="bg-black justify-center items-center pt-10 px-16"
        style={{ display: "flex" }}
      >
        <p
          style={{
            color: "var(--BRAND-COLOURS-ONYX, #1F5EFF)",
            textAlign: "center",
            lineHeight: "100%" /* 60px */,
            fontFamily: "KHTeka",
            fontSize: isMobile ? "32px" : "60px", // Dynamic font size based on viewport width
            fontStyle: "bold",
            fontWeight: "1000",
            letterSpacing: "-0.6px",
            textTransform: "uppercase",
          }}
        >
          Unfortunately, Existing safety apps ARE:
        </p>
      </div>
      <div
        className={`bg-black justify-center items-center pt-0 ${
          isMobile ? "px-3" : "px-16"
        } flex-col`}
        style={{ display: "flex" }}
      >
        <img src={reactiveImg} alt="reactive" className="w-full mb-3 px-3" />
        <p
          style={{
            color: "var(--BRAND-COLOURS-BLUE, #1F5EFF)",
            textAlign: "center",
            lineHeight: "100%", // 60px
            fontFamily: "KHTeka",
            fontSize: "calc(5vw + 10px)", // Dynamic font size based on viewport width
            fontStyle: "normal",
            fontWeight: "700",
            letterSpacing: "-0.6px",
            textTransform: "uppercase",
          }}
        >
          RATHER THAN
        </p>
        <img
          src={preventativeImg}
          alt="preventative"
          className="w-full  px-3"
        />
      </div>
    </div>
  );
};

export default SafetyAppsSection;
