import React from "react";
import revstream from "../assets/img/revstream.svg";
import r1 from "../assets/img/rev1.svg";
import r2 from "../assets/img/rev2.svg";
import r3 from "../assets/img/rev3.svg";
import caseStudy from "../assets/img/casestudy.webp";
import revstream1 from "../assets/img/revstreams.svg";
import revstream2 from "../assets/img/revstreams2.webp";

const RevenueStreams = () => {
  // const [isMobile, setIsMobile] = useState(window.innerWidth < 700);

  // useEffect(() => {
  //   const handleResize = () => {
  //     setIsMobile(window.innerWidth < 1000);
  //   };

  //   window.addEventListener("resize", handleResize);

  //   return () => {
  //     window.removeEventListener("resize", handleResize);
  //   };
  // }, []);

  return (
    <div className="h-full w-full ">
      <div className="flex flex-col justify-between items-center p-4 lg:p-16 bg-[#1F5EFF] h-full">
        <img
          src={revstream}
          alt="Revenue Streams"
          className="w-full px-3  py-10"
        />
        <div className="flex flex-row w-full justify-between px-3">
          <button
            onClick={() =>
              window.open(
                "https://apps.apple.com/us/app/get-home-safe/id6473676583",
                "_blank"
              )
            }
            className="full"
          >
            <img src={r1} alt="" className="" />
          </button>
          <button
            onClick={() => window.open("mailto:sales@gethomesafe.io")}
            className="full"
          >
            <img src={r2} alt="" className="" />
          </button>

          <button
            onClick={() => window.open("mailto:sales@gethomesafe.io")}
            className="full"
          >
            <img src={r3} alt="" className="" />
          </button>
        </div>
      </div>
      <div className="flex flex-col justify-between items-center p-4 lg:p-16 bg-black">
        <img src={caseStudy} alt="caseStudy" className="w-full px-3  py-10" />
        <img
          src={revstream1}
          alt="Revenue Streams"
          className="w-full px-3  py-10"
        />
        <img
          src={revstream2}
          alt="Revenue Streams"
          className="w-full px-3  py-10"
        />
      </div>
    </div>
  );
};

export default RevenueStreams;
