import React, { useEffect, useState } from "react";
import ThreeImageComp from "./ThreeImageComp";

// Importing images
import c1 from "../assets/img/campaign1.webp";
import c2 from "../assets/img/campaign2.webp";
import c3 from "../assets/img/campaign3.webp";
import campaignLogo from "../assets/img/campaignText.webp";
import campaignLogoMobile from "../assets/img/campaignText_Mobile.webp";

const descriptions = [
  {
    img: c1,
    title: "1. Building a safe community",
    text: "Users’ ID must be verified to access the platform. Women have each other’s backs - we want to build a community around this.",
  },
  {
    img: c2,
    title: "2. Flexibility like never before",
    text: "Our platform will empower women to enjoy their night without worrying about travelling alone. Everyone deserves stress-free nights out! ",
  },
  {
    img: c3,
    title: "3. Saving Money, Together",
    text: "Safety shouldn't be a luxury. By sharing rides women can keep each other safe and split the cost of travelling.",
  },
];

const DescriptionSection = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 1000);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 1000);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <>
      <img
        alt="campaignLogo"
        src={isMobile ? campaignLogoMobile : campaignLogo}
        className={isMobile ? "w-full px-3" : "w-full px-32"}
      />
      <ThreeImageComp descriptions={descriptions} />
    </>
  );
};

export default DescriptionSection;
