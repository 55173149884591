import React from "react";

const VerifyEmailPage = () => {
  const handleRedirectToApp = () => {
    // Redirect the user to your app using the custom URL scheme
    window.location.href = "gethomesafe-app://";
  };

  return (
    <div className="flex flex-col justify-center items-center h-screen bg-black text-white">
      <p className="text-lg font-semibold mb-4 text-center">
        Your email has been authenticated.
      </p>
      <button
        onClick={handleRedirectToApp}
        className="bg-[#7E44FB] hover:bg-[#6735D5] text-white font-semibold py-2 mx-auto self-center px-4 rounded focus:outline-none focus:shadow-outline"
      >
        Continue to the app
      </button>
    </div>
  );
};

export default VerifyEmailPage;
