import React, { useEffect, useState } from "react";
import Slider from "react-slick";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import scotch from "../assets/partner_logos/scotch.svg";
import elab from "../assets/partner_logos/elab.svg";
import icl from "../assets/partner_logos/icl.svg";
import icu from "../assets/partner_logos/icu.svg";
import nightcap from "../assets/partner_logos/nightcap.svg";
import ntia from "../assets/partner_logos/ntia.svg";
// import uber from "../assets/partner_logos/uber.svg";
import vawgs from "../assets/partner_logos/vawgs.svg";
import wya from "../assets/partner_logos/wya.svg";

const logos = [
  scotch,
  elab,
  icu,
  nightcap,
  icl,
  ntia,
  // uber,
  vawgs,
  wya,
];

const PartnersSlider = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 1000);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 1000);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  React.useEffect(() => {
    document.documentElement.style.overflowX = "hidden";
    document.body.style.overflowX = "hidden";
    return () => {
      document.documentElement.style.overflowX = "auto";
      document.body.style.overflowX = "auto";
    };
  }, []);

  const settings = {
    dots: false,
    infinite: true,
    slidesToShow: isMobile ? 2 : 4,
    slidesToScroll: 1,
    autoplay: true,
    speed: 2000,
    autoplaySpeed: 2000,
    cssEase: "linear",
    // arrow: false,
  };

  return (
    <div className="bg-black h-auto">
      <p
        className="text-white text-center"
        style={{
          color: "#F4F1E9",
          textAlign: "center",
          fontFamily: "GT Flexa",
          fontVariationSettings: "'wght' 550, 'wdth' 0",
          fontSize: "64px",
          // fontWeight: 715,
          lineHeight: "78%",
          textTransform: "uppercase",
          margin: "0",
          padding: "20px 0", // Adjusted padding
        }}
      >
        OUR PARTNERS
      </p>
      <Slider {...settings} className="pb-10 pt-2">
        {logos.map((logo, index) => (
          <div key={index} className="container items-center justify-center">
            <img
              src={logo}
              alt={`logo${index}`}
              className="h-[100px] self-center mx-auto"
            />
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default PartnersSlider;
