import React, { useEffect } from "react";
import FAQSubSection from "../components/FAQSubSection";
import FooterSection from "../components/FooterSection";
import MenuButton from "../components/MenuButton";

const safetyQnA = [
  {
    q: "How do you verify that my sex and picture matches my ID?",
    a: "We scan your government issues ID and ensure that the selfie you take during sign up matches the image on your ID. Make sure that your sex is visible on the ID.",
  },
  {
    q: "How does the app prioritize rider safety during shared rides?",
    a: "The app prioritizes safety through features like ID verification, real-time ride tracking by our team, and an in-app emergency button. We also provide safety guidelines and 24/7 support for any concerns.",
  },
  {
    q: "Who should I contact if something happens on my ride?",
    a: "Contact our support team immediately through the app's emergency feature or use local emergency services in critical situations.",
  },
  {
    q: "What steps should I take if I experience harassment or uncomfortable situations with a co-rider?",
    a: "Report any incidents of harassment or discomfort immediately through the app's reporting feature or contact customer support. We take such reports seriously and will take appropriate action to address the situation.",
  },
];

const communityQnA = [
  {
    q: "I feel my gender is not represented",
    a: "We aim to be inclusive and continuously update our platform. Please contact us directly to suggest how we can better represent your gender identity.",
  },
  {
    q: "Are females just allowed on the platform?",
    a: "Initially, during Phase 1, the platform is only accessible to females. In the subsequent phases, the service will be opened up to everyone. Check our phased approach on the Mission page for more details.",
  },
  {
    q: "I have a male friend with me, can I add him as a guest?",
    a: "Initially, during Phase 1, only women guests are allowed. Check our phased approach on the Mission page for more details.",
  },
  {
    q: "Why was I blocked?",
    a: "Accounts may be blocked due to violations of our community guidelines or safety policies. Contact support for details and possible resolution.",
  },
  {
    q: "How do I report someone?",
    a: "Use the 'Report' button in the app to report inappropriate behaviour or safety concerns about a co-rider or ride. Provide as much detail as possible to assist our review and ensure a safe community.",
  },
  {
    q: "Will they be informed of my reviews when I leave?",
    a: "Reviews are shared anonymously so feel free to express how you felt.",
  },
  {
    q: "Are there any incentives or rewards for frequent users of the app?",
    a: "Frequent users may receive rewards through a loyalty program to encourage continued use of the app with better, more accurate matches. The more praises you get, the more likely and quickly you will be matched.",
  },
];

const appQnA = [
  // {
  //   q: "Why have you created a new app?",
  //   a: "We created Get Home Safe to address safety concerns and provide a cost-effective, shared ride solution for community members, focusing initially on women's safety.",
  // },
  {
    q: "How to request a ride?",
    a: "To request a ride, input your starting location and desired destination, then select your preferences for co-riders. The app will then match you with others based on shared routes and preferences for you to invite when at the meet-up point.",
  },
  {
    q: "Can I use it to travel with someone on public transport or walk with them?",
    a: "If you do not want to share a ride, you can also find travel partners that will take public transport or walk with you. Our aim is to match you with users that will make you feel safer on your journey back home.",
  },
  {
    q: "How can I insert few destinations in the request details?",
    a: "You can add multiple destinations in the ride request by selecting the '+' icon in the destination field and inputting each address accordingly.",
  },
  {
    q: "How to change language settings of the app?",
    a: "Go to the app settings, find the 'Language' option, and select your preferred language from the list available.",
  },
  {
    q: "How do I edit my personal information?",
    a: "Navigate to your profile in the app, select 'Edit Profile,' and update your personal information as needed.",
  },
  {
    q: "What are the co-rider filters for?",
    a: "Filters offer users the option to select their co-rider preferences such as, people from the same university or workplace.",
  },
  {
    q: "Why have you created a new app?",
    a: "We created Get Home Safe to address safety concerns raised by women when travelling alone at night. Getting an Uber alone is the safest option, but it’s usually very expensive. We built a platform for users to share rides, resulting in a safer and more affordable travel option for women.",
  },
  {
    q: "Can I message possible co-riders?",
    a: "Yes, once matched, you can message your co-riders within the app to coordinate details of your shared ride.",
  },
  {
    q: "Can I see reviews or ratings of potential co-riders before accepting a match?",
    a: "Yes, you can view anonymized reviews and ratings of potential co-riders to make informed decisions before accepting a ride match.",
  },
];

const rideQnA = [
  {
    q: "If I don't wanna leave but have already created the group, is there anything I can do?",
    a: "You can press the “cancel ride” button, which allows you to go back and look for other riders when you want.",
  },
  {
    q: "Is it only for women?",
    a: "We are taking a phased approach to allow users to access the platform. We start with a women-only phase, focusing on safety and building trust. After establishing a strong foundation, we'll open up to all genders.",
  },
  {
    q: "Will I be able to adjust my destination whilst riding?",
    a: "No, destinations must be set before the ride starts. To change your destination, you must cancel the ride and create a new request.",
  },
  {
    q: "Can I use it only at night time, or is it available also throughout the day?",
    a: "The app is available 24/7, allowing you to find co-riders and share rides at any time of the day or night.",
  },
  {
    q: "Can I specify preferences for co-riders beyond gender, such as age or mutual interests?",
    a: "Yes, we have already included the possibility to select your co-riders based on mutual friends, university attended, and if you are willing to share with people with guests. In subsequent versions, we will be including other filters such as age and mutual interests, allowing for a more personalized ride-sharing experience.",
  },
  {
    q: "What happens if my co-rider cancels at the last minute?",
    a: "If a co-rider cancels last minute, you will automatically see compatible co-riders that are on the app at the meet-up point (or the app will suggest an alternative time if there is no one). You can create a new group with the co-riders and ride with them.",
  },
];

const paymentQnA = [
  {
    q: "How are the prices calculated?",
    a: "Prices are calculated based on the distance of your destination in the shared route, including any applicable surge pricing, and the number of riders. Sharing a ride divides the cost among all passengers according to your part of the journey.",
  },
  {
    q: "Are there any fixed fares?",
    a: "No, there are no fixed fares. The cost of each ride varies based on distance, time of day, and the number of co-riders.",
  },
  {
    q: "Do I have to pay to be on the app and get matched?",
    a: "No, creating an account and getting matched with co-riders is free. You only pay for the rides you take.",
  },
  {
    q: "I have a friend with me, can I include her in my trip? How does she pay her share?",
    a: "Yes, you can add your friend to your ride by selecting the ‘Add Guest’ option. The fare will be split among all registered riders, and you will be paying for both your share and hers.",
  },
  {
    q: "What payment methods do you accept?",
    a: "We accept various payment methods, including credit/debit cards, PayPal, and mobile payment apps.",
  },
];

const accountQnA = [
  {
    q: "Do I need to be 18 to use the app?",
    a: "Yes, you must be at least 18 years old to use the app, as it requires adult consent and adherence to our community guidelines.",
  },
  {
    q: "How do I update or change my email?",
    a: "To update or change your email, go to your profile settings in the app, select 'Account Information,' and then 'Email Address' to enter your new email.",
  },
  {
    q: "Is my photo verification selfie going to be my profile pic?",
    a: "No, your photo verification selfie is not used as your profile picture. You can choose a different photo for your profile picture in the app settings.",
  },
  {
    q: "Socials in bio",
    a: "You can add your social media handles to your bio in the app settings. Navigate to your profile, select 'Edit Profile,' and enter your social media information in the designated section.",
  },
  {
    q: "How can I deactivate my account if I no longer wish to use the service?",
    a: "To deactivate your account, go to the app settings, select 'Account Options,' and follow the instructions for account deactivation. You can also contact support for assistance.",
  },
  {
    q: "Where is GHS available?",
    a: "Get Home Safe is currently available in London. Check the app or our website for a list of future supported locations.",
  },
];

const FAQSection = () => {
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top when component mounts
  }, []); // Empty dependency array ensures the effect runs only once when mounted

  return (
    <div className="w-full py-20 bg-black">
      <MenuButton />
      <FAQSubSection
        title="SAFETY & SECURITY"
        qna={safetyQnA}
        primary={"#000000"}
        secondary={"#C140FF"}
      />
      <FAQSubSection
        title="COMMUNITY & POLICIES"
        qna={communityQnA}
        primary={"#C140FF"}
        secondary={"#F48F14"}
      />
      <FAQSubSection
        title="APP USAGE & FEATURES"
        qna={appQnA}
        primary={"#000000"}
        secondary={"#C140FF"}
      />
      <FAQSubSection
        title="Ride Management & Preferences"
        qna={rideQnA}
        primary={"#03AF70"}
        secondary={"#FEDB01"}
      />
      <FAQSubSection
        title="PAYMENT & PRICING"
        qna={paymentQnA}
        primary={"#000000"}
        secondary={"#C140FF"}
      />
      <FAQSubSection
        title="Account & Profile Management"
        qna={accountQnA}
        primary={"#1F5EFF"}
        secondary={"#F699DF"}
      />
      <FooterSection className="flex" />
    </div>
  );
};

export default FAQSection;
