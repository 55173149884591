const PrivacyPolicyPage = () => {
  return (
    <iframe
      src="PrivacyPolicyPage.html"
      title="Privacy Policy"
      style={{ width: "100%", height: "100vh", backgroundColor: "white" }}
      // sandbox="allow-same-origin allow-scripts"
    ></iframe>
  );
};

export default PrivacyPolicyPage;
