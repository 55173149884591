import React, { useEffect, useState } from "react";
// import React, { useState } from "react";
import MailIcon from "../assets/icons/mail.svg";
import Logo from "../assets/icons/logo.svg";
import { useNavigate } from "react-router-dom";

const FooterSection = () => {
  const [emailSubmitted, setEmailSubmitted] = useState(false);
  const navigate = useNavigate();
  const [isMobile, setIsMobile] = useState(window.innerWidth < 1000);

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      setEmailSubmitted(true);
    }
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 1000);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const textStyle = {
    color: "var(--white, #FFF)",
    fontFamily: "KHTeka",
    fontSize: isMobile ? "13.27px" : "24px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "30px" /* 123.612% */,
  };

  const joinStyle = {
    color: "var(--white, #FFF)",
    leadingTrim: "both",
    textEdge: "cap",
    fontFamily: "GT Flexa",
    fontVariationSettings: "'wght' 550, 'wdth' 0",
    fontSize: "40px",
    // fontStyle: "normal",
    // fontWeight: "715",
    lineHeight: "98%" /* 39.2px */,
    // letterSpacing: "0px",
    textTransform: "uppercase",
  };

  return (
    <footer className={`bg-black ${isMobile ? "py-[50px]" : "py-[100px]"}`}>
      <hr
        style={{
          border: "none",
          borderBottom: "3px solid white",
          opacity: "100%",
        }}
      />

      <div
        className={`text-white p-10 flex ${
          isMobile ? "flex-col" : "flex-row"
        } justify-between  w-full items-center`}
      >
        <div
          className={`flex-row items-center self-start mb-10 ${
            isMobile ? "w-full" : "w-1/2"
          }`}
        >
          <div className="flex-col">
            <div className="flex-row items-center" style={{ display: "flex" }}>
              <p className="ml-2 mr-4" style={joinStyle}>
                Join Waiting List
              </p>
              <img src={MailIcon} alt="Mail Icon" className="h-12 mb-3" />
            </div>
            <div className="relative">
              <input
                placeholder="Enter your email address"
                className="mt-2 px-2 py-1 placeholder:text-gray-100 border-b-2 border-gray-500 "
                style={textStyle}
                onKeyPress={handleKeyPress}
              />
              {emailSubmitted && (
                <p className="pt-2 ml-2 text-gray-500 font-KH-Teka">
                  Email submitted!
                </p>
              )}
            </div>
          </div>
        </div>

        {/* Pages Section */}
        <div
          className="flex-col items-center justify-center self-center"
          style={{ display: "flex" }}
        >
          <button
            className="mb-2"
            style={textStyle}
            onClick={() => navigate("/")}
          >
            Home
          </button>
          <button
            className="mb-2"
            style={textStyle}
            onClick={() => navigate("/Mission")}
          >
            Mission
          </button>
          <button
            className="mb-2"
            style={textStyle}
            onClick={() => navigate("/Partners")}
          >
            Partners
          </button>
          <button
            className="mb-2"
            style={textStyle}
            onClick={() => navigate("/FAQs")}
          >
            FAQs
          </button>
        </div>

        {/* Socials Section */}
        <div
          className="flex-col items-center justify-start self-center"
          style={{ display: "flex" }}
        >
          <button
            className="mb-2"
            style={textStyle}
            onClick={() =>
              window.open("https://www.instagram.com/gethomesafe.io/", "_blank")
            }
          >
            Instagram
          </button>
          <button
            className="mb-2"
            style={textStyle}
            onClick={() =>
              window.open("https://www.tiktok.com/@gethomesafe.io", "_blank")
            }
          >
            TikTok
          </button>
        </div>
        <div></div>
      </div>
      <img
        src={Logo}
        alt="Get Home Safe"
        className="self-start mx-auto pt-[100px]"
      ></img>
    </footer>
  );
};

export default FooterSection;
