import React, { useState, useEffect } from "react";
// import Logo from "../assets/icons/logo.svg";
import MenuButton from "../components/MenuButton";
import heroText from "../assets/img/partners_media.svg";
import heroImg from "../assets/img/partners_media.webp";
import { useNavigate } from "react-router-dom";

const SignInPage = () => {
  const [isMobile, setIsMobile] = useState(false);
  const [menuButtonHeight, setMenuButtonHeight] = useState(0);
  const [password, setPassword] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    // Calculate the height of the MenuButton component
    const menuButton = document.getElementById("menuButton");
    if (menuButton) {
      const height = menuButton.offsetHeight;
      setMenuButtonHeight(height);
    }
  }, []);
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 710);
    };

    // Initial check on component mount
    handleResize();

    // Event listener for window resize
    window.addEventListener("resize", handleResize);

    // Cleanup on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleLogin = () => {
    console.log("Logging in...");
    console.log(password);
    // Check if the password is correct
    if (password === "ERO") {
      // If correct, set isLoggedIn to true
      sessionStorage.setItem("signedInPartners", "true");
      navigate("/Partners/Home");
    } else {
      // If incorrect, alert the user
      alert("Incorrect password. Please try again.");
    }
  };

  return (
    <div className="flex">
      <MenuButton className={``} />
      <div
        className={`vh-100 flex flex-column justify-between align-items-center w-full ${
          isMobile ? "p-3" : "px-16"
        } bg-black`}
        style={{
          backgroundImage: `url(${heroImg})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <div></div>
        <img
          src={heroText}
          alt="Partners Sign In Text"
          // className={`mt-[${menuButtonHeight}px]`}
          style={{ marginTop: menuButtonHeight }}
        />

        <div
          className={`flex flex-col items-center p-4 rounded-lg ${
            isMobile ? "w-full" : "w-1/2"
          }`}
        >
          <p className="text-black text-center">
            See the insights, vision and progress of Get Home Safe. Please enter
            the password you were provided to view.
          </p>
          <input
            type="password"
            placeholder="PASSWORD"
            // style={{
            //   placeholderColor: "black",
            // }}
            onChange={(e) => setPassword(e.target.value)}
            className="w-full m-2 text-center text-black bg-white text-lg leading-tight  p-3 rounded-md border-2 border-gray-700 focus:outline-none focus:border-white"
          />
          <button
            type="button"
            className="bg-[#03AF70] m-2 p-3 w-full rounded-lg text-black font-bold text-lg shadow-md hover:bg-green-400 focus:outline-none focus:ring-2 focus:ring-pink-300 focus:ring-opacity-50"
            onClick={handleLogin}
          >
            LOGIN
          </button>
          <p className="text-black text-xs text-center">
            Don’t have a password? request one at{" "}
            <a href="mailto:partners@gethomesafe.io" className="text-black">
              partners@gethomesafe.io
            </a>
          </p>
        </div>
      </div>
    </div>
  );
};

export default SignInPage;
